import {
  addBreadcrumb,
  captureException,
  captureMessage,
  withScope,
} from "@sentry/nextjs";

export const enum SentryLevel {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  DEBUG = "debug",
}
type OptionsParam<T = any> = { [key: string]: T };
type CaptureExceptionOptions = {
  contexts?: OptionsParam<OptionsParam>;
  extra?: OptionsParam;
  fingerprint?: string[];
  tags?: OptionsParam<string>;
};
type CaptureMessageOptions = {
  level?: SentryLevel;
} & CaptureExceptionOptions;

export function sentryCaptureException(
  error?: string | Event | Error | null,
  options: CaptureExceptionOptions = {},
) {
  if (!error) {
    return;
  }

  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== "production") {
    console.error(error, JSON.stringify(options, null, 2));
  }

  let exception: Error;
  if (typeof error === "string") {
    exception = new Error(error);
  } else if (error instanceof Event) {
    exception = new Error(String(error));
  } else {
    exception = error;
  }

  withScope((scope) => {
    if (options.contexts) {
      Object.keys(options.contexts).forEach((key) => {
        scope.setContext(key, options.contexts![key]);
      });
    }
    if (options.extra) {
      scope.setExtras(options.extra);
    }
    if (options.fingerprint) {
      scope.setFingerprint(options.fingerprint);
    }
    if (options.tags) {
      scope.setTags(options.tags);
    }

    captureException(exception);
  });
}

export function sentryCaptureMessage(
  message: string,
  options: CaptureMessageOptions = {},
) {
  withScope((scope) => {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV !== "production") {
      console.error(message, JSON.stringify(options, null, 2));
    }

    if (options.contexts) {
      Object.keys(options.contexts).forEach((key) => {
        scope.setContext(key, options.contexts![key]);
      });
    }
    if (options.extra) {
      scope.setExtras(options.extra);
    }
    if (options.fingerprint) {
      scope.setFingerprint(options.fingerprint);
    }
    if (options.tags) {
      scope.setTags(options.tags);
    }
    if (options.level) {
      scope.setTag("level", options.level);
    }

    captureMessage(message);
  });
}

export function sentryAddBreadcrumb(
  message: string,
  level: SentryLevel,
  data: any = {},
) {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== "production") {
    console.error(message, JSON.stringify(data, null, 2));
  }

  addBreadcrumb({
    message: message,
    level: level,
    data: data,
  });
}
