'use client'

import { sentryCaptureException } from "@/lib/sentry"
import { useEffect } from "react"

 
export default function Error({
    error,
    reset,
  }: {
    error: Error & { digest?: string }
    reset: () => void
  }) {
    useEffect(() => {
      sentryCaptureException(error)
    }, [error])
   
    return (
      <div>
        <h2>Something went wrong. When I see this I will call our A team and fix it!</h2>
        <button
          onClick={
            // Attempt to recover by trying to re-render the segment
            () => reset()
          }
        >
          Try again
        </button>
      </div>
    )
  }